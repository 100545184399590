.micStyles {
position: fixed;
bottom: 20px;
right: 60px;
z-index: 100
}

.recipeStyle {
    position: absolute;
    padding: 10px;
    background-color: white;
    max-width: 90%
}

.titleStyle {
    align-items: center;
    text-align: center;
    width: 100vw;
    height: 1000px
}

.cardStyle {
    display: flex;
    width: 70vw;
    margin: 0 auto;
}
 .ingredientImage {
     max-height: 60vh;
     width: auto;
 }

 .carrousel {
     margin: 0 auto;
 }

 .recipeSteps {
     background-color: #f5daa4;
 }

 .caption {
     color: white;
     font-weight: bold;
 }
